import React, {Component} from 'react';
import styles from './ServicesPage.module.css'
import Aux from '../../hoc/Auxiliary/Auxiliary'
import {FaCarAlt, FaOilCan, FaWrench} from 'react-icons/fa';

class BrakesPage extends Component {

render() {

    return(
        <Aux>
        <div className={styles.background}>
        <div className="jumbotron ">
            <div className='container'>
        <h1 className=' border-bottom'><FaCarAlt/>  Brake Service</h1>
        <p className='mb-0'>Batavia Automotive</p>
        <p>50 Libery St Batavia NY | <a href="tel:585-344-7171">585-344-7171</a></p>
        <p className="font-weight-light mt-2 mb-0 text-white "><button className={styles.rose}><a style={{textDecoration: 'none', color: 'white', paddingRight: '5px', paddingLeft: '5px'}} href='/Appointment'> Book Appointment </a></button></p>
        </div>
        </div>
        <div className='container'>
        <h2 className='text-center pb-4'>If you need brake maintenance or repair, We can help!  </h2>
        
        
         <h4 className='text-center pb-4'>Your brakes are a vital safety feature, so if you're unsure of their integrity, please get in touch with us right away!</h4>


        <h4><strong>Key Components:</strong></h4>
        
        <p><strong>Master Cyclinder</strong></p>
         <p>The master cylinder is responsible for powering the entire braking system. When the driver places pressure on the brake pedal, this will signal the master cylinder to send hydraulic fluid through the brake lines. The hydraulic power will activate the brakes pistons and cause the vehicle to slow or stop.</p>
        
         <p><strong>Brake Lines</strong></p>
        <p>A car’s brake lines are designed to transport brake fluid throughout the braking system. The master cylinder is connected to the other parts of the braking system through a series of brake lines. If the brake lines become damaged, a car may start to leak brake fluid.</p>
       
        <p><strong>Brake Caliper</strong></p>
        <p>The brake caliper is designed to house all of the different mechanical components of a braking system. Inside the brake caliper, you will find the brake piston, brake pads, and brake rotors. If a car is experiencing braking problems, the technician may need to disassemble the brake caliper in order to repair the issue.</p>
        
        <p><strong>Brake Pads</strong></p>
        <p>A brake pad replacement is one of the most common brake repair services that any car owner will need to schedule. The brake pads are designed to grip the brake rotors, causing the vehicle to slow down or stop. Since the brake pads receive a high degree of friction, they will wear down more quickly than any other brake component.</p>
        
        <p><strong>Brake Rotors</strong></p>
        <p>The brake rotors are directly connected to the wheels of a car. As pressure is placed on the brake pads, the brake rotors will stop the rotation of the wheels.</p>

        </div>
        </div>
        <div>
        

        </div>
        </Aux>
    )



}



}


export default BrakesPage;