import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChatBot from 'react-simple-chatbot';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import Backdrop from '../UI/Backdrop/Backdrop';

class Review extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      gender: '',
      age: '',
    };
  }

//   shouldComponentUpdate(nextProps, nextState) {
//     return nextProps.show !== this.props.show || nextProps.children !== this.props.children;
// }

  componentWillMount() {
    const { steps } = this.props;
    const { name, gender, age } = steps;

    this.setState({ name, gender, age });
  }

  render() {
    const { name, gender, age } = this.state;
    return (
      <div style={{ width: '100%' }}>
        <h3>Summary</h3>
        <table>
          <tbody>
            <tr>
              <td>Name</td>
              <td>{name.value}</td>
            </tr>
            <tr>
              <td>Gender</td>
              <td>{gender.value}</td>
            </tr>
            <tr>
              <td>Age</td>
              <td>{age.value}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

Review.propTypes = {
  steps: PropTypes.object,
};

Review.defaultProps = {
  steps: undefined,
};

class SimpleForm extends Component {
  render() {
    return (
        <Aux>
        {/* <Backdrop show={this.props.chatshow} clicked={this.props.chatClosed} /> */}
     <div  
     className="w-100"
    //  style={{
    //                     zIndex: '105',
    //                     paddingBottom: '70px',
    //                     position: 'relative',
    //                     transform: this.props.chatshow ? "translateY(0)" : "translateY(-100vh)",
    //                     opacity: this.props.chatshow ? '1' : '0'
    //                 }}
                    >
      <ChatBot
        steps={[
        //   {
        //     id: '1',
        //     message: 'What is your name?',
        //     trigger: 'name',
        //   },
        //   {
        //     id: 'name',
        //     user: true,
        //     trigger: '3',
        //   },
          {
            id: '3',
            message: "Hi! I'm the ShopBot How can I help you today?",
            trigger: 'help',
          },
          {
            id: 'help',
            options: [
              { value: 'contact', label: 'Submit Contact Form', trigger: '5' },
              // { value: 'appointment', label: 'Book Appointment', trigger: '66' },
              // { value: 'call', label: 'Speak to a human Now', trigger: '77'},
            ],
          },
          //Contact ME
          {
            id: '5',
            message: 'Whats Your First Name',
            trigger: 'firstname',
          },
          {
            id: 'firstname',
            user: true,
            trigger: '6',
            // validator: (value) => {
            //   if (isNaN(value)) {
            //     return 'value must be a number';
            //   } else if (value < 0) {
            //     return 'value must be positive';
            //   } else if (value > 120) {
            //     return `${value}? Come on!`;
            //   }

            //   return true;
            // },
          },
          {
            id: '6',
            message: 'Whats Your Last Name',
            trigger: 'lastname',
          },
          {
            id: 'lastname',
            user: true,
            trigger: '7',
            // validator: (value) => {
            //   if (isNaN(value)) {
            //     return 'value must be a number';
            //   } else if (value < 0) {
            //     return 'value must be positive';
            //   } else if (value > 120) {
            //     return `${value}? Come on!`;
            //   }

            //   return true;
            // },
          },
          {
            id: '7',
            message: 'Whats Your Phone Number',
            trigger: 'phone',
          },
          {
            id: 'phone',
            user: true,
            trigger: '8',
            // validator: (value) => {
            //   if (isNaN(value)) {
            //     return 'value must be a number';
            //   } else if (value < 0) {
            //     return 'value must be positive';
            //   } else if (value > 120) {
            //     return `${value}? Come on!`;
            //   }

            //   return true;
            // },
          },
          {
            id: '8',
            message: 'Whats Your Email Address',
            trigger: 'email',
          },
          {
            id: 'email',
            user: true,
            trigger: '9',
            // validator: (value) => {
            //   if (isNaN(value)) {
            //     return 'value must be a number';
            //   } else if (value < 0) {
            //     return 'value must be positive';
            //   } else if (value > 120) {
            //     return `${value}? Come on!`;
            //   }

            //   return true;
            // },
          },
          {
            id: '9',
            message: 'Would you like to add vehicle information?',
            trigger: 'moreinfo',
          },
          {
            id: 'moreinfo',
            options: [
              { value: 'Yes', label: 'Yes', trigger: '10' },
              { value: 'No', label: 'No', trigger: '13' },
              // { value: 'appointment', label: 'Book Appointment', trigger: '66' },
              // { value: 'call', label: 'Speak to a human Now', trigger: '77'},
            ],
            // validator: (value) => {
            //   if (isNaN(value)) {
            //     return 'value must be a number';
            //   } else if (value < 0) {
            //     return 'value must be positive';
            //   } else if (value > 120) {
            //     return `${value}? Come on!`;
            //   }

            //   return true;
            // },
          },
          {
            id: '10',
            message: 'Whats Your vehicles Year',
            trigger: 'year',
          },
          {
            id: 'year',
            user: true,
            trigger: '11',
            // validator: (value) => {
            //   if (isNaN(value)) {
            //     return 'value must be a number';
            //   } else if (value < 0) {
            //     return 'value must be positive';
            //   } else if (value > 120) {
            //     return `${value}? Come on!`;
            //   }

            //   return true;
            // },
          },
          {
            id: '11',
            message: 'Whats Your vehicles make',
            trigger: 'make',
          },
          {
            id: 'make',
            user: true,
            trigger: '12',
            // validator: (value) => {
            //   if (isNaN(value)) {
            //     return 'value must be a number';
            //   } else if (value < 0) {
            //     return 'value must be positive';
            //   } else if (value > 120) {
            //     return `${value}? Come on!`;
            //   }

            //   return true;
            // },
          },
          {
            id: '12',
            message: 'Whats Your vehicles model',
            trigger: 'model',
          },
          {
            id: 'model',
            user: true,
            trigger: '13',
            // validator: (value) => {
            //   if (isNaN(value)) {
            //     return 'value must be a number';
            //   } else if (value < 0) {
            //     return 'value must be positive';
            //   } else if (value > 120) {
            //     return `${value}? Come on!`;
            //   }

            //   return true;
            // },
          },
          {
            id: '13',
            message: 'Please add some details on your contact request',
            trigger: 'details',
          },
          {
            id: 'details',
            user: true,
            trigger: '17',
            // validator: (value) => {
            //   if (isNaN(value)) {
            //     return 'value must be a number';
            //   } else if (value < 0) {
            //     return 'value must be positive';
            //   } else if (value > 120) {
            //     return `${value}? Come on!`;
            //   }

            //   return true;
            // },
          },

          //review
          {
            id: '17',
            message: 'Great! Check out your summary',
            trigger: 'review',
          },
          {
            id: 'review',
            component: <Review />,
            asMessage: true,
            trigger: 'update',
          },
          {
            id: 'update',
            message: 'Would you like to update some field?',
            trigger: 'update-question',
          },
          {
            id: 'update-question',
            options: [
              { value: 'yes', label: 'Yes', trigger: 'update-yes' },
              { value: 'no', label: 'No', trigger: 'end-message' },
            ],
          },
          {
            id: 'update-yes',
            message: 'What field would you like to update?',
            trigger: 'update-fields',
          },
          {
            id: 'update-fields',
            options: [
              { value: 'name', label: 'Name', trigger: 'update-name' },
              { value: 'gender', label: 'Gender', trigger: 'update-gender' },
              { value: 'age', label: 'Age', trigger: 'update-age' },
            ],
          },
          {
            id: 'update-name',
            update: 'name',
            trigger: '7',
          },
          {
            id: 'update-gender',
            update: 'gender',
            trigger: '7',
          },
          {
            id: 'update-age',
            update: 'age',
            trigger: '7',
          },
          {
            id: 'end-message',
            message: 'Thanks! Your data was submitted successfully!',
            end: true,
          },
        ]}
      />
      </div>
      </Aux>
    );
  }
}

export default SimpleForm;