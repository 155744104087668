import React, {Component} from 'react';
import styles from './ServicesPage.module.css';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import {FaCarAlt, FaOilCan, FaWrench} from 'react-icons/fa';

class GeneralRepairPage extends Component {

render() {

    return(
        <Aux>
         
        <div className={styles.background}>
        <div className="jumbotron ">
        <div className='container'>
        <h1 className=' border-bottom'><FaWrench/>General Repair</h1>
        <p className='mb-0'>Batavia Automotive</p>
        <p>50 Libery St Batavia NY | <a href="tel:585-344-7171">585-344-7171</a></p>
        <p className="font-weight-light mt-2 mb-0 text-white "><button className={styles.rose}><a style={{textDecoration: 'none', color: 'white', paddingRight: '5px', paddingLeft: '5px'}} href='/Appointment'> Book Appointment </a></button></p>

        </div>
        </div>
        <div className='container'>
        <h2 className='text-center pb-4'>If you need any general maintenance or repair, We can help!  </h2>
        
        
         <h4 className='text-center pb-4'>Your questions and concerns are very important to us, please get in touch with us for any problems you may be experiencing!</h4>
        </div>
        </div>
        </Aux>
    )



}



}


export default GeneralRepairPage;