import React, { Component } from 'react';
// import styles from './Review.module.css';
import styles from '../Testimonials/Testimonials.module.css';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import Spinner from '../UI/Spinner/Spinner';
import axios from 'axios';
// import dateFormat from 'dateformat';

import {Carousel} from 'react-bootstrap';
import pic7 from '../../assets/images/pic7.jpg';
import {BsStarFill} from 'react-icons/bs';


let reviews = null


const url = 'https://uype5xue44.execute-api.us-east-1.amazonaws.com/prod/bataviareviews';

class Reviews extends Component {

    state = {
      tours: [],
      loading: true,
      reviewsArray: []
    }
    
    //  tourList() {
    //   const tourslist = {
    //     ...this.state.tours
    //   }

    //   return (
    //     <ol>
    //       {this.state.tours.map(tour => <li>{tour.Summary}</li>)}
    //     </ol>
    //   );
    // }

    componentDidMount() {
      axios.get(url)
          .then(res => {

              const fetchedTours = []
              for (let Id in res.data) {
                  fetchedTours.push({
                      ...res.data[Id],
                      id:Id
                  });
              }
              this.setState({tours: fetchedTours});
              console.log(this.state.tours)


                // const fetchedratings = [];
                // for (let Id in this.state.tours[1].reviews) {
                //     fetchedratings.push({
                //         ...this.state.tours[1].reviews[Id],
                //         id:Id
                //     });
                // }
                // this.setState({reviewsArray: fetchedratings});
                // console.log(this.state.reviewsArray)


                const newratings = [];
                for (let Id in this.state.tours[1].reviews) {
                    if(this.state.tours[1].reviews[Id].rating >= 4){
                        newratings.push(this.state.tours[1].reviews[Id]);
                    }
                   
                }
                this.setState({reviewsArray: newratings});
                console.log(this.state.reviewsArray)


                // const newratings = [];
                // for (let Id in this.state.reviewsArray) {
                //     if(this.state.reviewsArray[Id].rating >= 4){
                //         newratings.push(this.state.reviewsArray[Id]);
                //     }
                   
                // }
                // this.setState({reviewsArray: newratings});
                // console.log(this.state.reviewsArray)


              if (this.state.tours.length === 0){
                reviews = (
                  <div className={styles.NoTourItems}>
                <div className='text-center p-3'>
                  <h1>No Reviews, Check Back Later!!</h1>
                </div>
                </div>
                 ) 
              } else {
                reviews = (
                  <div>
                      <div className={styles.background}>
                        <img className={styles.Testimonials} src={pic7} alt='star'/>
                        <div className={styles.centered}>
                        <h1>Google Reviews</h1>
                        <Carousel indicators={false}>
                           
                       
                      
                      {this.state.tours.map(tour => 



                            <Carousel.Item>
                         
                        <div className={styles.TourItems}>
                          <div className=' align-items-center'>
                           
                           <div className=''>
                           <p className='text-center'>{tour.author_name}</p>
                           </div>
                           <div className=''>
                            <p className='text-center'>{tour.rating}  <BsStarFill style={{color:'gold'}}/>  {tour.relative_time_description}</p>
                           </div>
                           <div className=''>
                           <p className='text-center'>{tour.text.slice(0, 250)}</p>
                           </div>
                           </div>
                        </div>
                            <Carousel.Caption>
                            </Carousel.Caption>
                        </Carousel.Item>

                     
                        // <div className={styles.TourItems}>
                        //   <div className=' align-items-center'>
                           
                        //    <div className=''>
                        //    <p className='text-center'>{tour.author_name}</p>
                        //    </div>
                        //    <div className=''>
                        //     <p className='text-center'>{tour.rating + " *Star Rating " + tour.relative_time_description}</p>
                        //    </div>
                        //    <div className=''>
                        //    <p className='text-center'>{tour.text}</p>
                        //    </div>
                        //    </div>
                        // </div>




                         )}
                    
                                      </Carousel>
                                        
                                        </div>
                                        </div>
                
                  
                </div>
                 ) 
              }
              
               console.log(reviews)
               this.setState({loading: false})
              // console.log(this.state.tours);
          })
          .catch (err => {
              this.setState({loading: false});
          });
  }

    render(){

      if(this.state.loading) {
        reviews = <Spinner />
      }
  
        return (
            <Aux > 

            {/* <div  className={styles.eee}>
             
              
            </div> */}

          <div >
            <div > 
                {reviews}
            </div>
          </div>
            </Aux>

        )
       
    }
}


export default Reviews;