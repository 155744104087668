import React from 'react'
import Aux from '../hoc/Auxiliary/Auxiliary';
import styles from './Footer.module.css';
import logo from '../assets/images/radiantLogoSmwhite.png';

const footer = () => (
    <Aux>
        <div className={styles.Footer}>
            <div className={styles.Footer1}> 
                <h3 className='pl-2 text-center'>Quick Links</h3>
                <ul className='row pl-0 text-center'>
                    <li className='col-6 col-sm-3'><a href='/'>Home</a></li>
                    <li className='col-6 col-sm-3'><a href='/About-Us'>About Us</a></li>
                    <li className='col-6 col-sm-3'><a href='/Contact'>Contact Us</a></li>
                    <li className='col-6 col-sm-3'><a href='/Appointment'>Appointment</a></li>
                    <li className='col-6 col-sm-3'><a href='/Brakes '>Brakes</a></li>
                    <li className='col-6 col-sm-3'><a href='/Oil-Service'>Oil Service</a></li>
                    <li className='col-6 col-sm-3'><a href='/Tires'>Tires</a></li>
                    <li className='col-6 col-sm-3'><a href='/Transmission'>Transmission</a></li>
                    {/* <li className='col-6 col-sm-3'><a href='/General-Repair'>General Repair</a></li>
                    <li className='col-6 col-sm-3'><a href='/Inspections'>NYS Inspections</a></li> */}
                    
                </ul>
            </div>
      
        <div className='row pt-3 text-center'>
           
            <div className='col-6'>
                <p className="mb-0">50 Liberty Street Batavia, NY 14020</p>
                <p>585-344-7171</p>
            </div>
            <div className='col-6'>
            <p className="mb-0">Powered by: <a style={{textDecoration: 'none', color: 'white'}} href='https://myradiantsolution.com' ><img className={styles.logo} src={logo} alt='logo' /> Radiant Solutions</a></p>

            </div>
        </div>
        </div>
    </Aux>
)

export default footer;