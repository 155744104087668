import React, {Component} from 'react';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import {TiMessages} from 'react-icons/ti';
import styles from './ContactPage.module.css';
import BookingForm from '../../components/Forms/BookingForm';

class ContactPage extends Component {

render() {

    return(
        <Aux>
        <div className={styles.background}>
        <div className="jumbotron ">
        <div className='container'>
        <h1 className='border-bottom'> <TiMessages style={{paddingRight: '10px',  fontSize: '2.7rem'}}/> Contact Request</h1>
        <p className='mb-0'>Batavia Automotive</p>
        <p>50 Libery St Batavia NY | <a href="tel:585-344-7171">585-344-7171</a></p>
        </div>
        
        
       
        
        
        </div>
        <BookingForm/>
        </div>

        
        </Aux>
    )



}



}


export default ContactPage;