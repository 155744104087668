import React, {Component} from 'react';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import styles from './AppointmentPage.module.css';
import AppointmentForm from '../../components/Forms/AppointmentForm';
import {GoCalendar} from 'react-icons/go';

class AppointmentPage extends Component {

render() {

    return(
        <Aux>
        <div className={styles.background}>
        <div className="jumbotron ">
        <div className='container'>
        <h1 className='border-bottom'> <GoCalendar style={{paddingRight: '10px',  fontSize: '2.7rem'}}/> Appointment Request</h1>
        <p className='mb-0'>Batavia Automotive</p>
        <p>50 Libery St Batavia NY | <a href="tel:585-344-7171">585-344-7171</a></p>
        </div>
       
      
        </div>
        </div>

        <AppointmentForm/>
        </Aux>
    )



}



}


export default AppointmentPage;