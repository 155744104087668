import React from 'react';
import {Navbar,Nav} from 'react-bootstrap';
// import logo from '../../../assets/images/Logo.png';
import styles from './NavigationItems.module.css';
import pic11 from '../../assets/images/pic11.jpg';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import {Cross as Hamburger} from 'hamburger-react'
import wrench from '../../assets/images/wrenchWhite.png';

const navigationItems = () => (
  <Aux>
  <div className={styles.Back}>
<Navbar fixed="top" collapseOnSelect expand="lg" className={styles.Navigation}>
  <Navbar.Brand style={{color: 'white'}} href="/"><img className={styles.Logo} src={wrench} alt="logo"/> </Navbar.Brand>
  <div className={styles.Company}><h3>Batavia Automotive</h3></div>
  
  <Navbar.Toggle style={{color: 'white'}} aria-controls="responsive-navbar-nav" >
  <Hamburger direction="right"/>
  </Navbar.Toggle>
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
      {/* <Nav.Link href="/">Home</Nav.Link>
      <Nav.Link href="/Booking">Book the Band</Nav.Link>
      <Nav.Link href="/Gallery">Gallery</Nav.Link> */}
    </Nav>
    <Nav >
   
      <button className=" m-2 btn-dark" > <Nav.Link className='text-white' href="/">Home</Nav.Link></button>
      <button className=" m-2 btn-dark"><Nav.Link className='text-white' href="/About-Us">About-Us</Nav.Link></button>
      <button className=" m-2 btn-dark"><Nav.Link className='text-white'href="/Contact">Contact</Nav.Link></button>
      <button className="m-2 btn-sm" style={{color: "white", backgroundColor: `rgb(41,188,158)`}}><Nav.Link className='text-white' href="/Appointment">Book Appointment</Nav.Link></button>
      {/* <Nav.Link  href="/Upload">Upload Content </Nav.Link> */}
    </Nav>
  </Navbar.Collapse>
</Navbar>
</div>
</Aux>
)

export default navigationItems;
