import React from 'react';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import styles from './Services.module.css';

const Services = () => (
    <Aux>
        <div className='jumbotron text-center'>

            <h2 >Batavia Automotive</h2>
            <p>Consistent Service You Can Rely On!</p>
            
        </div>
        <div className='p-3'>

            <h3 className=' text-center'>No Appointments required for Oil Changes - First Come, First Served!</h3>
            <br></br>
            <p  className='container text-center'>Batavia Automotive Inc. is the automotive expert in Batavia. Since 2012 we've provided the Batavia area with reliable, and affordable automotive maintenance and repair services. Stop by and become a part of the batavia automotive family, that has been proudly serving the local area for almost 10 years! </p>
            <br></br>
                </div> 
    </Aux>
)

export default Services;
