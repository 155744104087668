import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import styles from './Map.module.css';

class map extends Component {
    // static defaultProps = {
    //     center: {
    //       lat: 42.994502,
    //       lng: -78.180313
    //     },
    //     zoom: 17
    //   };
     

      render() {
        return (

            
            <div >
            
           
            <div className="">
            <embed style={{
                 width: "100%",
                 height: "450px",
                //  frameBorder: "0" ,
                //  style: "border:0"
            }}
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyB7pqjdvahNpPu1BMNIDl-OJpqHdE5uMU4&q=Batavia+Automotive+inc+NY" 
            allowfullScreen
            />
        
        </div>
        
        </div>

          // Important! Always set the container height explicitly
        //   <div className={styles.Map} style={{ height: '100vh', width: '100%' }}>
        //     <GoogleMapReact
        //       bootstrapURLKeys={{ key: 'AIzaSyB7pqjdvahNpPu1BMNIDl-OJpqHdE5uMU4' }}
        //       defaultCenter={this.props.center}
        //       defaultZoom={this.props.zoom}
        //       yesIWantToUseGoogleMapApiInternals
        //       text="Batavia Auto"
        //     >
              
        //     </GoogleMapReact>
        //   </div>
        );
      }
    }



   


export default map;
