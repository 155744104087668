import React from 'react';
import Aux from '../hoc/Auxiliary/Auxiliary';
import styles from './CertBanner.module.css';
import cert1 from '../assets/images/cert1.png';
import cert2 from '../assets/images/cert2.gif';

const certBanner = () => (
    <Aux>
        <div className={styles.CertBanner} style={{backgroundColor: 'white'}}>
            <div className=" m-auto d-flex justify-content-center container">
                <img src={cert1} alt="cert"/>
                <img src={cert2} alt="cert"/>
            </div>
        </div>
    </Aux>
)

export default certBanner;