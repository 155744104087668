import React from 'react';
import './App.css';
import Aux from './hoc/Auxiliary/Auxiliary';
import CertBanner from './components/CertBanner';
import BataviaSlogan from './components/BataviaSlogan';
import Footer from './components/Footer';
import NavigationItems from './components/Navbar/NavigationItems';
import Reviews from './components/Reviews/Reviews';
import Home from './containers/HomePage/Home';
import {Route, Switch } from 'react-router-dom';
import BrakesPage from './containers/ServicesPage/BrakesPage';
import GeneralRepairPage from './containers/ServicesPage/GeneralRepairPage';
import InspectionsPage from './containers/ServicesPage/InspectionsPage';
import OilPage from './containers/ServicesPage/OilPage';
import TiresPage from './containers/ServicesPage/TiresPage';
import TransmissionPage from './containers/ServicesPage/TransmissionPage';
import AppointmentPage from './containers/AppointmentPage/AppointmentPage';
import ContactPage from './containers/ContactPage/ContactPage';
import AboutUs from './containers/AbouUs/AboutUs';


function App() {
  return (
    <Aux>
      <NavigationItems/>
      <Switch>
        <Route path='/About-Us' component={AboutUs} />
        <Route path='/Brakes' component={BrakesPage} />
        <Route path='/Tires' component={TiresPage} />
        <Route path='/Inspections' component={InspectionsPage} />
        <Route path='/Oil-Service' component={OilPage} />
        <Route path='/Transmission' component={TransmissionPage} />
        <Route path='/General-Repair' component={GeneralRepairPage} />
        <Route path='/Contact' component={ContactPage} />
        <Route path='/Appointment' component={AppointmentPage} />
        <Route path='/' exact component={Home} />
      </Switch>
       {/* <BataviaSlogan /> */}
      <CertBanner />
      {/* <div style={{height:'150px'}}> */}
      <Reviews/>
      {/* </div> */}
      <Footer />
    </Aux>
      
    
  );
}

export default App;
