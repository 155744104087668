import React, {Component} from 'react';
import styles from './AboutUs.module.css';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import {FiPhone} from 'react-icons/fi';
import Map from '../../components/Map/Map';
import bapic3 from '../../assets/images/bapic3.JPG';
import {FcAbout} from 'react-icons/fc';

class AboutUs extends Component {

render() {

    return(
        <Aux>
            <div className={styles.background}>
            <div className="jumbotron ">
            <div className='container'>
            <h1 className='border-bottom'> <FcAbout style={{paddingRight: '10px',  fontSize: '2.7rem'}}/> About Us</h1>
            <p className='mb-0'>Batavia Automotive</p>
            <p>50 Libery St Batavia NY | <a href="tel:585-344-7171">585-344-7171</a></p>
            </div>
           </div>
            </div>
            <div className="row pb-3">

            <div className='col-lg-6'>
            <div className='container text-center'>
                <h1  className='pb-2'>Batavia Automotive Inc.</h1>
                <h3 className='pb-2'><a href="tel:585-344-7171"><FiPhone/></a> Call for Service</h3>
                <p  className='container pb-2'>Batavia Automotive has provided fast and reliable services for more than 10 years. It has been owned and operated by our local family, and we take pride in the work that we do.  </p>
                
                <img className={styles.bapic} src={bapic3} alt="bapic"/>
                
            </div>
            </div>
            
            <div className='text-center col-lg-6 pt-3'>
            <table class="container w-75 table table-striped table-dark">
    <thead>
      <tr>
        
        <th scope="col" class="text-center">Day</th>
        <th scope="col" class="text-center">Hours</th>

      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row" class="text-center">Sunday</th>
        <td class="text-center">Closed</td>
        
      </tr>
      <tr>
        <th scope="row" class="text-center">Monday</th>
        <td class="text-center">8:00AM - 6:00PM</td>
        
      </tr>
      <tr>
          <th scope="row" class="text-center">Tuesday</th>
          <td class="text-center">8:00AM - 6:00PM</td>
          
        </tr>
        <tr>
            <th scope="row" class="text-center">Wednesday</th>
            <td class="text-center">8:00AM - 6:00PM</td>
            
          </tr>
          <tr>
              <th scope="row" class="text-center">Thursday</th>
              <td class="text-center">8:00AM - 6:00PM</td>
              
            </tr>
            <tr>
                <th scope="row" class="text-center">Friday</th>
                <td class="text-center">8:00AM - 6:00PM</td>
                
              </tr>
              <tr>
                  <th scope="row" class="text-center">Saturday</th>
                  <td class="text-center">8:00AM - 3:00PM</td>
                  
                </tr>

      
    </tbody>
  </table>

            </div>
           
            </div>
        <Map/>
        </Aux>
        
    )



}



}


export default AboutUs;