import React, {Component} from 'react';
import styles from './ServicesPage.module.css';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import {GiFlatTire} from 'react-icons/gi';

class TiresPage extends Component {

render() {

    return(
        <Aux>
        <div className={styles.background}>
        <div className="jumbotron ">
            <div className='container'>
        <h1 className=' border-bottom'><GiFlatTire/>Tire Service</h1>
        <p className='mb-0'>Batavia Automotive</p>
        <p>50 Libery St Batavia NY | <a href="tel:585-344-7171">585-344-7171</a></p>
        <p className="font-weight-light mt-2 mb-0 text-white "><button className={styles.rose}><a style={{textDecoration: 'none', color: 'white', paddingRight: '5px', paddingLeft: '5px'}} href='/Appointment'> Book Appointment </a></button></p>

        </div>
        </div>
        <div className='container'>
        <h2 className='text-center pb-4'>If you need tire maintenance or repair, We can help!  </h2>
        
        
         <h4 className='text-center pb-4'>Your Tires are a vital component, so if you're unsure of their status, please get in touch with us right away!</h4>


        <h4><strong>Key Components:</strong></h4>
        
        <p><strong>Tire Pressure</strong></p>
         <p>Under-inflation can lead to tire failure. It results in unnecessary tire stress, irregular wear, loss of control and accidents. A tire can lose up to half of its air pressure and not appear to be flat.</p>
        
         <p><strong>Tire Alignment</strong></p>
        <p>A bad jolt from hitting a curb or pothole can throw your front end out of alignment and damage your tires. Misalignment of wheels in the front or rear can cause uneven and rapid treadwear and should be corrected by a tire dealer. Front-wheel-drive vehicles, and those with independent rear suspension, require alignment of all four wheels. Have your alignment checked periodically as specified by the vehicle owner’s manual or whenever you have an indication of trouble such as “pulling” or vibration.</p>
       
        <p><strong>Tire Blanance</strong></p>
        <p>Have your tire balance checked periodically. An unbalanced tire and wheel assembly may result in irregular wear.</p>
        
        <p><strong>Tire Rotation</strong></p>
        <p>You can slow down uneven tread wear by rotating your tires which simply means moving them around so that they “trade places” on your vehicle in a systematic way. Rotation is important because each tire on a car carries a different amount of weight, making them wear at different rates. By rotating them, you basically even out those differences. Your owner’s manual will tell you how often to rotate your tires, but as a rule of thumb, it should be done every 6,000 to 8,000 miles. You might want to rotate them sooner if you see signs of uneven wear. Misalignment and other mechanical problems can also cause such wear, so check with your mechanic to determine the cause.</p>
        
        <p><strong>Tire Tread</strong></p>
        <p>Another critical part of your tire is the tread, which gives you the traction to stop and hold the road on curves. Tire tread also funnels water out from under the tire, which helps to reduce “hydroplaning,” where a car actually rides up on a layer of water and becomes dangerously difficult to steer or stop.</p>

        </div>
        </div>
        <div>
        

        </div>
        </Aux>
    )



}



}


export default TiresPage;