import React, { Component } from 'react';
import styles from './Modal.module.css';
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import Backdrop from '../Backdrop/Backdrop';

class Modal extends Component {

shouldComponentUpdate(nextProps, nextState) {
    return nextProps.show !== this.props.show || nextProps.children !== this.props.children;
}

componentDidUpdate () {
    console.log("modal update");
}

    render () {
        return (
            <Aux>
            <Backdrop show={this.props.show} clicked={this.props.modalClosed} />
                <div 
                    className={styles.Modal}
                    style={{
                        transform: this.props.show ? "translateY(0)" : "translateY(-100vh)",
                        opacity: this.props.show ? '1' : '0'
                    }}
                    >
                        <div className={styles.modalGuts}>
                    {this.props.children}
                    </div>
                </div>
        </Aux>
    );
        
    }
}

  

export default Modal