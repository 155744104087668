import React, {Component} from 'react';
import styles from './ServicesPage.module.css';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import {FcInspection} from 'react-icons/fc';

class InspectionsPage extends Component {

render() {

    return(
        <Aux>
        <div className={styles.background}>
        <div className="jumbotron ">
            <div className='container'>
        <h1 className=' border-bottom'><FcInspection/>NY State Inspection</h1>
        <p className='mb-0'>Batavia Automotive</p>
        <p>50 Libery St Batavia NY | <a href="tel:585-344-7171">585-344-7171</a></p>
        <p className="font-weight-light mt-2 mb-0 text-white "><button className={styles.rose}><a style={{textDecoration: 'none', color: 'white', paddingRight: '5px', paddingLeft: '5px'}} href='/Appointment'> Book Appointment </a></button></p>

        </div>
        </div>
        <div className='container'>
        <h2 className='text-center pb-4'>If you need an inspection, We can help!  </h2>
        
        
         <h4 className='text-center pb-4'>Your inspection is required by law, so if you're unsure of its status, please get in touch with us right away!</h4>



        <h4><strong>What gets Inspected?</strong></h4>
        
        <p><strong>Inspection Sticker</strong></p>
         <p>If your inspection sticker does not properly stick onto the windshield, you are allowed to use clear tape or a clear adhesive. Please be aware that the inspection facility must be able to remove the inspection sticker when you come in the following year.</p>
        
         <p><strong>Seat Belts</strong></p>
        <p>Your seat belts will be inspected for adequate function and anchorage. There must be one seat belt for every seat in your car and no seat belts missing. This also depends on the year model of your vehicles.
– Model years 1967 – Present – There must be one seat belt for every seat in your vehicle. </p>
       
        <p><strong>Air Bag Warning Lamp</strong></p>
        <p>If it is found to be not working right or at all, you will be notified</p>
        
        <p><strong>Horn</strong></p>
        <p>Your horn will be checked for proper mounting and if it is functioning properly.</p>
        
        <p><strong>Mirrors</strong></p>
        <p>Your mirrors will be checked for cracks, breaks and discoloration. They must be properly mounted and secured for optimum function. Any vehicles whose sight is obstructed through the rearview mirror must have a left and a right side mirror.</p>
        
        <p><strong>Brakes</strong></p>
        <p>There must be at least one tire removed to inspect your brakes. The condition and operation of all the components of your braking system will be inspected. Also, the inspector will check for any cracks and leaks. </p>
        
        <p><strong>Lights</strong></p>
        <p>The lighting system in your vehicle must be working properly. Your lights will be inspected for the proper type, missing lens, adequate mounting and function. </p>
        
        <p><strong>Brakes</strong></p>
        <p>There must be at least one tire removed to inspect your brakes. The condition and operation of all the components of your braking system will be inspected. Also, the inspector will check for any cracks and leaks. </p>
        
        <p><strong>Windshields</strong></p>
        <p>Your windshield and other glass in your vehicle must be properly mounted and in good condition. Also, your wipers and blades must be properly functioning and in good condition. </p>
        
        <p><strong>Tires</strong></p>
        <p>Your tires must have a 2/32 inch groove space on the area of the tire with the most wear. They will also be inspected for any knots, bulges and anything on the tire that can restrict its use. </p>
        
        <p><strong>Steeriung, Chassis, Suspension, Front End</strong></p>
        <p>The entire front end assembly of your vehicle will be inspected. The steering wheel play will be checked as well as all steering linkages and power steering. Your shock absorbers, and spring and torsion bars will be checked for any broken or sagging parts. The Chassis and wheel fasteners will be checked for any breaks, cracks and if any rust if present. </p>
        
        <p><strong>Emissions Inspection</strong></p>
        <p>Your vehicle is exempt from an emissions inspection if it falls into one of the following criteria: Less than two models old, 26 model years old or older, registered with historic plates, or electric powered only.</p>
        <p>In New York State, if your diesel powered vehicles is over 8,500 lbs and registered in the New York metropolitan area (including these counties; Nassau, Suffolk, Rockland and Westchester), your vehicle must undergo annual emissions inspections. All diesel powered vehicles that are 8,500 lbs over the maximum gross weight, may be subject to roadside emissions inspections statewide.</p>
        <p>An On-Board Diagnostics (OBDII) is conducted on vehicles under 8,500 lbs and that are the model year 1996 or newer. This inspection examines that your check engine light is functioning properly and verifies the results of the on-board diagnostics system.</p>
        <p>A low Enhanced Inspection is conducted on vehicles that are model year 1995 and older, up to 25 model years through and weigh 18,000 or less.
Even if your vehicle possess any one of these emissions control devices, it is still subject to an emissions test; Catalytic converter, Exhaust gas recirculation valve, Positive crankcase ventilation system, Air injection system, Evaporative emissions control, Fuel inlet restrictor, and Thermostatic air cleaner.</p>
        <p>In case any of the technicians at Middle Country Automotive find any issue with your vehicle, such as failing your emissions inspection, they can always aid you in offering any of your auto inspection or auto repair needs in Selden and Centereach.</p>

        </div>
        </div>
        </Aux>
    )



}



}


export default InspectionsPage;