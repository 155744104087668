import React from 'react';
import Aux from '../hoc/Auxiliary/Auxiliary';
import styles from './ServicesGrid.module.css';
import pic9 from '../assets/images/pic9.jpg';
import pic8 from '../assets/images/pic8.jpg';
import pic10 from '../assets/images/pic10.jpg';
import pic14 from '../assets/images/pic14.jpg';
import pic5 from '../assets/images/pic5.jpg';
import pic4 from '../assets/images/pic4.jpg';
import pic3 from '../assets/images/pic3.jpg';
import pic16 from '../assets/images/pic16.jpg';
import pic17 from '../assets/images/pic17.jpg';
import {FaCarAlt, FaOilCan, FaWrench} from 'react-icons/fa';
import {GiFlatTire} from 'react-icons/gi';
import {BsGear} from 'react-icons/bs';
import {FcInspection} from 'react-icons/fc';

const servicesGrid = () => (
    <Aux>
        <div className="pt-3 m-auto" >
            <h2 className="text-center pt-3 pb-3">OUR SERVICES</h2>
            <div className="row m-auto d-flex justify-content-center">
               
               
                <div  className= " col-md-3 p-0 m-3 mt-0 " style={{backgroundColor: 'rgb(246,249,252)'}}>
                <a href="/Brakes" className="styles.Servicelink" style={{textDecoration: 'none', color: 'inherit'}}> 
                <div className={styles.Card}>
                    <div className={styles.Icon}><FaCarAlt/></div>
                    <div className="card-body p-0  text-center">
                    <h4 style={{ color: 'black'}} className="card-title">Brakes</h4>
                        <p style={{fontSize: '1rem', color: 'black'}} className="card-text w-75 m-auto">
                            Pads, Rotors, Calipers all should be checked regularly for wear. 
                        </p>
                    </div>
                </div>
                </a>
                </div>

                <div  className= " col-md-3 p-0 m-3 mt-0 " style={{backgroundColor: 'rgb(246,249,252)'}}>
                <a href="/Oil-Service" className="styles.Servicelink" style={{textDecoration: 'none', color: 'inherit'}}>
                <div className={styles.Card}>
                    <div className={styles.Icon}><FaOilCan/></div>
                    <div className="card-body p-0  text-center">
                    <h4 style={{ color: 'black'}} className="card-title">Oil Service</h4>
                        <p style={{fontSize: '1rem', color: 'black'}} className="card-text w-75 m-auto">
                        When was the last time you had your oil changed? Its recommended to be changed every 3000 miles. 
                        </p>
                    </div>
                </div>
                </a>
                </div>

                <div  className= " col-md-3 p-0 m-3 mt-0 " style={{backgroundColor: 'rgb(246,249,252)'}}>
                <a href="/Tires" className="styles.Servicelink" style={{textDecoration: 'none', color: 'inherit'}}>
                <div className={styles.Card}>
                    <div className={styles.Icon}><GiFlatTire/></div>
                    <div className="card-body p-0  text-center">
                    <h4 style={{ color: 'black'}} className="card-title">Tires</h4>
                        <p style={{fontSize: '1rem', color: 'black'}} className="card-text w-75 m-auto">
                            Where the rubber meets the road. You'll want to check these regularly  or you are going to have a bad day.
                        </p>
                    </div>
                </div>
                </a>
                </div>

                <div  className= " col-md-3 p-0 m-3 mt-0 " style={{backgroundColor: 'rgb(246,249,252)'}}>
                <a href="/General-Repair" className="styles.Servicelink" style={{textDecoration: 'none', color: 'inherit'}}>
                <div className={styles.Card}>
                    <div className={styles.Icon}><FaWrench/></div>
                    <div className="card-body p-0  text-center">
                    <h4 style={{ color: 'black'}} className="card-title">General Repair</h4>
                        <p style={{fontSize: '1rem', color: 'black'}} className="card-text w-75 m-auto">
                        No problem is too small or large for our qualified service technicians. Bring any and all problems you may have. 
                        </p>
                    </div>
                </div>
                </a>
                </div>

                <div  className= " col-md-3 p-0 m-3 mt-0 " style={{backgroundColor: 'rgb(246,249,252)'}}>
                <a href="/Transmission" className="styles.Servicelink" style={{textDecoration: 'none', color: 'inherit'}}>
                <div className={styles.Card}>
                    <div className={styles.Icon}><BsGear/></div>
                    <div className="card-body p-0  text-center">
                    <h4 style={{ color: 'black'}} className="card-title">Transmission Repair</h4>
                        <p style={{fontSize: '1rem', color: 'black'}} className="card-text w-75 m-auto">
                            A very costly repair if left unchecked. Keep peace of mind with regular transmission maintenance. Your wallet will thank you. 
                        </p>
                    </div>
                </div>
                </a>
                </div>
              
                <div  className= " col-md-3 p-0 m-3 mt-0 " style={{backgroundColor: 'rgb(246,249,252)'}}>
                <a href="/Inspections" className="styles.ServiceLink" style={{textDecoration: 'none', color: 'inherit'}} >
                <div className={styles.Card}>
                    <div className={styles.Icon}><FcInspection/></div>
                    <div className="card-body p-0  text-center">
                    <h4 style={{ color: 'black'}} className="card-title">NYS Inspections</h4>
                        <p style={{fontSize: '1rem', color: 'black'}} className="card-text w-75 m-auto">
                            Not just an oportunity for you to give charitable donation to the state. This is a great time to also conduct often neglected regualr maintenance.
                        </p>
                    </div>
                </div>
                </a>
                </div>





            </div>
        </div>
    </Aux>
 
)

export default servicesGrid;