import React, {Component} from 'react';
import styles from './ServicesPage.module.css';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import {FaCarAlt, FaOilCan, FaWrench} from 'react-icons/fa';

class OilPage extends Component {

render() {

    return(
        <Aux>
        <div className={styles.background}>
        <div className="jumbotron ">
            <div className='container'>
        <h1 className=' border-bottom'><FaCarAlt/>Oil Service</h1>
        <p className='mb-0'>Batavia Automotive</p>
        <p>50 Libery St Batavia NY | <a href="tel:585-344-7171">585-344-7171</a></p>
        </div>
        </div>
        <div className='container'>
        <h2 className='text-center pb-4'>If you need oil maintenance or repair, We can help!  </h2>
        
        
         <h4 className='text-center pb-4'>Your oil is a vital component, so if you're unsure of its status, please get in touch with us right away!</h4>


        <h4><strong>Key Components:</strong></h4>
        
        <p><strong>Oil Pan</strong></p>
         <p>Holds the oil required for the system, provides a means of draining the oil through the oil plug and houses the oil pump and pick up tube</p>
        
         <p><strong>Oil Pump</strong></p>
        <p>Provides a continuous supply of oil at sufficient pressure and quantity to provide adequate lubrication to the entire engine. The pump is driven either by the crankshaft, camshaft, distributor or timing belt.</p>
       
        <p><strong>Oil Filter</strong></p>
        <p>The function of the oil filter is to remove dust, dirt, sludge, and water before it reaches engine parts.</p>
        
        <p><strong>Oil Pressure Indicator</strong></p>
        <p>Gauge or light to indicate oil pressure problems. The indicator is connected electrically to an oil pressure switch or “sending unit”.</p>
        
        <p><strong>Oil Level Indicator</strong></p>
        <p>Also known as dipstick, indicates the level of the oil in the oil pan and sometimes has information such as the type of oil recommended by the manufacturer. Some vehicles have an electronic sensor in the oil pan to indicate low oil levels.</p>

        </div>
        </div>
        <div>
        

        </div>
        </Aux>
    )



}



}


export default OilPage;