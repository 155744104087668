import React, {Component} from 'react';
import styles from './ServicesPage.module.css';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import {BsGear} from 'react-icons/bs';

class TransmissionPage extends Component {

render() {

    return(
        <Aux>
        <div className={styles.background}>
        <div className="jumbotron ">
            <div className='container'>
        <h1 className=' border-bottom'><BsGear/>  Transmission Repair</h1>
        <p className='mb-0'>Batavia Automotive</p>
        <p>50 Libery St Batavia NY | <a href="tel:585-344-7171">585-344-7171</a></p>
        <p className="font-weight-light mt-2 mb-0 text-white "><button className={styles.rose}><a style={{textDecoration: 'none', color: 'white', paddingRight: '5px', paddingLeft: '5px'}} href='/Appointment'> Book Appointment </a></button></p>

        </div>
        </div>
        <div className='container'>
        <h2 className='text-center pb-4'>If you need transmission maintenance or repair, We can help!  </h2>
        
        
         <h4 className='text-center pb-4'>Your transmission is a vital component, so if you're unsure of it's integrity, please get in touch with us right away!</h4>


        <h4><strong>Key Concepts:</strong></h4>
        
        <p><strong>Planetary Gear Set</strong></p>
         <p>At the heart of any automatic transmission lies the planetary gear set. The planetary gear set gets its name due to its striking similarity to the way that our plants orbit around the sun in the solar system. Each planetary gear set is composed of a sun gear and planet gear. These gears arrange themselves in different combinations as you travel down the road and change speeds.</p>
        
         <p><strong>Torque Converter</strong></p>
        <p>Unlike a manual transmission, which contains a clutch, the automatic transmission is equipped with a component that is called a torque converter. The job of the torque converter is to deliver power from a car’s engine to its transmission system. When the engine is idling and the car is not in motion, the torque converter absorbs the engine’s energy and prevents it from stalling. If your car experiences torque converter issues, you may find that your vehicle drives erratically.</p>
       
        <p><strong>Valve Body</strong></p>
        <p>Every automatic transmission requires transmission fluid in order to operate. The transmission fluid is controlled by a component that is known as the valve body. In order to push transmission fluid through the system, the valve body opens and closes as needed. Modern transmissions have computerized systems that control the valve body functions, as well as many other essential operations of the transmission system.</p>
        
        
        </div>
        </div>
        <div>
        

        </div>
        </Aux>
    )



}



}


export default TransmissionPage;