import React from 'react';
import Aux from '../hoc/Auxiliary/Auxiliary';
import styles from './AreasWeServe.module.css';
import { MdMyLocation } from 'react-icons/md';

const areasWeServe = () => (
    <Aux>
    <div className={styles.Areas}> 
        <div className="container text-center">
            <div className={styles.Areas}> 
             <h2 className="pb-3"> AREAS WE SERVE</h2>  
                <ul className="row p-0">
                    <li className="col-6 col-md-4 col-lg-3 p-2"><MdMyLocation/> Batavia</li>
                    <li className="col-6 col-md-4 col-lg-3 p-2"><MdMyLocation/> Oakfield</li>
                    <li className="col-6 col-md-4 col-lg-3 p-2"><MdMyLocation/> Stafford</li>
                    <li className="col-6 col-md-4 col-lg-3 p-2"><MdMyLocation/> South Byron</li>
                    <li className="col-6 col-md-4 col-lg-3 p-2"><MdMyLocation/> Elba</li>
                    <li className="col-6 col-md-4 col-lg-3 p-2"><MdMyLocation/> Leroy</li>
                    <li className="col-6 col-md-4 col-lg-3 p-2"><MdMyLocation/> East Bethany</li>
                    <li className="col-6 col-md-4 col-lg-3 p-2"><MdMyLocation/> Alexander</li>
                </ul>
            </div>
        </div>
    </div>
    </Aux>
  
);


export default areasWeServe;